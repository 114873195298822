const apiusertype = {
  insertUserType: "/v1/api/AdsUserType/InsertUsertype",
  updateUserType: "/v1/api/AdsUserType/UpdateUsertype/",
  deleteUserType: "/v1/api/AdsUserType/DeleteUserType/",
  getUserTypeList: "/v1/api/AdsUserType/UserTypeList",
  getUserTypeByID: "/v1/api/AdsUserType/UsertypeByID/",
  // duplicate: "/v1/api/AdsUserType/UserTypeAgency",
  checkdDeleteUserType: "/v1/api/AdsUserType/CheckDeleteusertype/",
  checkusedstatus: "/v1/api/AdsUserType/CheckUsageStatusUsertype/",
};

export default apiusertype;
