<template>
  <div class="bg-white shadow-sm rounded p-5 select-form">
    <div class="black-form" @click="backToView()">
      <SortArrow width="24" class="black-icon me-1 fill-secondary" />
      <span class="text-warning">{{ $t('5') }}</span>
    </div>
    <p class="fnt-medium my-3">{{ $t('906') }}</p>
    <div class="d-flex gap-3 justify-content-satrt">
      <div
        v-for="(item, index) in listTypeForm"
        :key="index"
        class="card-template border rounded p-5 d-flex align-items-center flex-column"
        :id="item.id"
        @click="selectForn(item.id, item.name)"
      >
        <div class="h-100 d-flex align-items-end">
          <DrafFile width="52" class="fill-black-500" />
        </div>
        <div class="h-100">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { SortArrow, DrafFile } from "@/components/icon/index";
export default {
  components: { SortArrow, DrafFile },
  data() {
    return {
      listTypeForm: [
        {
          id: 1,
          name: "Thai student template default",
          typeName: "ThaiStudent",
        },
        {
          id: 2,
          name: "Foreign student template default",
          typeName: "ForeigmStudent",
        },
        {
          id: 3,
          name: "Staff template default",
          typeName: "Staff",
        },
      ],
    };
  },
  emits: ["close"],
  methods: {
    selectForn(id, typeName) {
      // console.log(id);
      this.$emit("typeID", id);
      this.$emit("typeName", typeName);
    },
    backToView() {
      // console.log("Back to view template");
      this.$emit("close", false);
    },
  },
};
</script>
<style lang="scss" scoped>
.select-form {
  width: fit-content;
  .card-template {
    width: 350px;
    height: 150px;
    border-color: $black-500 !important;
    cursor: pointer;
    &:hover {
      background-color: $black-100;
    }
  }
  .black-form {
    cursor: pointer;
    width: fit-content;
    &:hover {
      text-decoration: underline;
      color: $secondary;
    }
    .black-icon {
      transform: rotate(90deg);
    }
  }
}
</style>