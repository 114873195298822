<template>
  <div
    class="popup-container align-items-center"
    v-show="blnPopupConfirmDelete == true && intWrongLogin < intPinCount"
  >
    <transition name="zoom">
      <Alert
        v-show="blnPopupConfirmDelete == true && intWrongLogin < intPinCount"
        type="Danger"
        classOkBtn="btn-danger"
        artIcon="deleteFill"
        :altMsgBox="$t('186', { fieldname: $t('5') })"
        :Helper="$t('345')"
        @confirm="fetchDeleteTemplate($event)"
        ref="AlertPopup"
        :showpass="true"
        :btnpop="true"
        classIcon="fill-danger"
        :errorMsg="$t(errorMsg)"
        :blnError="blnError"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnPopupConfirmDelete == true && intWrongLogin < intPinCount"
        @click="ConfirmDelete()"
      ></div>
    </transition>
  </div>
  <div
    class="popup-container align-items-center"
    v-show="blnPopupConfirmDelete == true && intWrongLogin >= intPinCount"
  >
    <transition name="zoom">
      <Alert
        v-show="blnPopupConfirmDelete == true && intWrongLogin >= intPinCount"
        type="Warning"
        classOkBtn="btn-secondary"
        artIcon="WarningOutline"
        :altMsgBox="$t('1287', { LONGINFAILNUM: intPinCount, LockContact: strTel })"
        :Helper="$t('345')"
        @confirm="reloadpage($event)"
        ref="AlertPopupp"
        :btnpop="true"
        classIcon="fill-warning"
        :Cancel="false"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnPopupConfirmDelete == true && intWrongLogin >= intPinCount"
      ></div>
    </transition>
  </div>
  <AltStatus
    :blnalert="blnAltStatus"
    icon="CorrectFill"
    coloricon="fill-success"
    class="success"
    :name="$t(strSMSGsuccess)"
    :class="blnAltStatus ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnAltStatusunsucess"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('174')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnAltStatusunsucess ? 'show' : ''"
  />
  <!-- พบข้อผิดพลาด(เว็บไซต์) ระบบกำลังกลับสู่หน้าแรก -->
  <AltStatus
    :blnalert="blnAltLoadingData"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('223')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnAltLoadingData ? 'show' : ''"
  />
  <!-- ข้อมูลนี้เพิ่งถูกลบไป ไม่สามารถลบได้ -->
  <AltStatus
    :blnalert="blnnotdelete"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('428')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnnotdelete ? 'show' : ''"
  />
  <!-- ข้อมูลนี้เพิ่งถูกลบไป ไม่สามารถแก้ไขได้ -->
  <AltStatus
    :blnalert="blnnotedit"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('222')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnnotedit ? 'show' : ''"
  />
  <div>
    <Header
      icon="templateoutline"
      :headerName="
        blnOpenform == true
          ? intTypeID == ''
            ? $t('906')
            : $t('900')
          : $t('5')
      "
      :headerNamebreadcrumb="$t('5')"
      :btnName="$t('151')"
      class="mb-3"
      @onInput="openform($event)"
      :breadcrumbname="
        blnOpenform == true && intTypeID == ''
          ? $t('906')
          : action == 'Insert'
          ? $t('900')
          : // $t('151')
          '' || action == 'Update'
          ? $t('426', { fieldname: $t('5').toLowerCase() })
          : ''
      "
      :breadcrumb="blnOpenform == true ? '' : 'd-none'"
      :helperHeader="
        blnOpenform == true
          ? intTypeID == ''
            ? $t('1024')
            : $t('200', { fieldname: $t('5').toLowerCase() })
          : ''
      "
      :hintHelperHeader="blnOpenform == true ? '' : 'd-none'"
      :rightHeader="blnOpenform == true ? 'd-none' : ''"
      :underline="blnOpenform == true ? 'openUnderline' : 'closeUnderline'"
      @showFilter="openFilter($event)"
      id="header"
      ListTemplateClass="d-none"
      :Searchname="$t('152')"
      @onSearch="SearcPosition($event)"
      @onClean="clean()"
      :blnOpenform="blnOpenform"
      :showFilter="blnOpenFilter"
      :filterActive="{
        blnOpenform: blnOpenFilter,
        useFilter: activeFiltersSelected.length == 0 ? false : true,
        disableFilter: false,
      }"
      @ConfigDisplay="reloadConfigDisplay()"
      ref="header"
    />
    <div class="template-form">
      <div class="popup-container" v-show="blnOpenFilter == true">
        <transition name="zoom">
          <Filter
            v-show="blnOpenFilter == true"
            @appectFilter="filteredData($event)"
            :listDataFilter="ListTemplateFilter"
            @Cancel="blnOpenFilter = !blnOpenFilter"
            :listActiveFiltersSelected="activeFiltersSelected"
            :blnHaveDecision="false"
            :blnOpenFilter="blnOpenFilter"
            ref="filter"
          />
        </transition>
        <transition name="fade">
          <div
            class="overlay"
            v-show="blnOpenFilter == true"
            @click="blnOpenFilter = false"
          ></div>
        </transition>
      </div>
      <Table
        :progressScore="progress"
        :nametable="'5'"
        v-show="ListTemplate.dataFields != null"
        v-if="!blnOpenform"
        :ListDataTable="ListTemplate"
        firstSort="intTemplatestatus"
        secondSort="strUsertemplatename"
        statusActiveName="197"
        statusInactiveName="198"
        :blnPreview="true"
        classBtnIconEdit="fill-black-500"
        @edit="fetchGetTemplatebyId($event)"
        @ConfigDisplay="reloadConfigDisplay()"
        @onSaveConfigDisplay="onSaveConfigDisplay($event)"
        @OnPreview="onPreview($event)"
        ref="table"
      />
      <div class="box">
        <div class="boxer d-flex m-0">
          <div class="mb-5">
            <SelectTemplate
              v-if="intTypeID == '' && blnOpenform == true"
              @close="
                (event) => {
                  openform(event);
                }
              "
              @typeID="
                (event) => {
                  intTypeID = event;
                }
              "
              @typeName="
                (event) => {
                  strTypeName = event;
                }
              "
            />
            <formTemplate
              v-if="blnOpenform == true && intTypeID != ''"
              @closeForm="
                () => {
                  blnOpenform = true;
                  intTypeID = '';
                }
              "
              :intTypeID="intTypeID"
              :strTypeName="strTypeName"
              :action="action"
            />
            <!-- <Template
              class="lefts"
              :listTemplateData="listTemplateData"
              :locales="locales"
              v-if="blnOpenform == true && intTypeID != ''"
              @onInput="openform($event)"
              @altMsg="altMsg($event)"
              :action="action"
              :TemplateId="TemplateId"
              :inactive="templateInactive"
              @delete="ConfirmDelete()"
            /> -->
          </div>
          <Relate
            :class="
              device == 'mobile' || device == 'tablet' ? 'mt-3 mb-3 w-100' : ''
            "
            v-if="blnOpenform == true && listRelate.used == true"
            :listRelate="listRelate"
            :label="$t('418', { fieldname: $t('5').toLowerCase() })"
            style="width: 350px"
            class="layout rights me-4"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Relate from "@/components/cards/relate.vue";
import Filter from "@/components/filter/filter.vue";
import Header from "@/components/headerMenu/haeder.vue";
import Table from "@/components/table/table.vue";
import urlapi from "@/typescript/urlapi/url";
import apitemplate from "@/typescript/urlapi/adminsystem/APITemplate";
import apiusertype from "@/typescript/urlapi/adminsystem/APIUsertype";
import axios from "axios";
// import Template from "@/components/form/Template/Template.vue";
//import SortArray from "../typescript/sort/sorting";
import Enumerable from "linq";
import AdsInventories from "@/typescript/inventory";
import Alert from "@/components/alert/alertPopup.vue";
import AltStatus from "@/components/alert/alertStatus.vue";
import Login from "@/typescript/login";
import SelectTemplate from "@/views/systemadmin/template/SelectTypeTemplate.vue";
import formTemplate from "@/views/systemadmin/template/formTemplate.vue";
import VueCookies from "vue-cookies";
import cookiesPath from "@/typescript/urlapi/cookiesPath";

export default {
  components: {
    Header,
    Table,
    Filter,
    // Template,
    Alert,
    AltStatus,
    Relate,
    SelectTemplate,
    formTemplate,
  },
  data() {
    return {
      blnloadError: false,
      strSearch: "",
      blnAlerMsgError: false,
      blnPopupConfirmDelete: false,
      intWrongLogin: 0,
      intPinCount: 1,

      progress: 0,
      action: "Insert",
      // typeSchema: typeSchema, //
      configDisplay: "SelectedListTemplate",
      formdata: {},
      blnOpenform: false,
      activeFiltersSelected: [],
      ListTemplate: {},
      ListUsertype: {},
      ListDummy: {},
      locales: "en",
      strSMSGsuccess: "",
      blnOpenFilter: false,
      blnnotedit: false,
      blnnotdelete: false,
      blnAltLoadingData: false,
      blnAltStatus: false, //Alt Status Success
      blnAltStatusunsucess: false, //Alt Status unsuccess
      filterUsertype: {},
      updateAdsInventories: null,
      ListTemplateFilter: [
        {
          list: [
            { name: "19" },
            {
              data: [
                { id: null, lang: "79" }, //all
                { id: 1, lang: "197" }, //active
                { id: 0, lang: "198" }, //inactive
              ],
            },
            { state: "intTemplatestatus" },
          ],
        },
      ],

      listTemplateData: [],
      templateInactive: false,
      listRelate: [],
      scrollLeft: Number,
      startX: Number,
      isDown: null,
      errorMsg: "",
      intTypeID: "",
      strTypeName: "",
    };
  },
  created() {
    this.processLoadingData();
  },
  unmounted() {
    clearInterval(this.updateAdsInventories);
  },
  mounted() {
    this.updateAdsInventories = setInterval(() => {
      if (AdsInventories.blnCloseform == false) {
        this.openform(false);
        AdsInventories.blnCloseform = true;
      }
    }, 500);
    this.blnOpenFilter = false;
  },
  //เรียกข้อมูล วิทยาเขต จากฐานข้อมูล ก่อนเปิดหน้าเว็บ
  beforeUnmount() {
    // window.removeEventListener("popstate");
  },
  methods: {
    ConfirmDelete() {
      if (this.$refs.AlertPopup != null) {
        this.$refs.AlertPopup.password = null;
        this.$refs.AlertPopup.blnShowMSG = false;
      }
      this.blnError = false;
      this.blnPopupConfirmDelete = !this.blnPopupConfirmDelete;
    },
    async processLoadingData() {
      this.intTypeID = "";
      this.progress = 0;
      this.action = "Insert";
      this.listTemplateData = [];
      this.TemplateId = null;
      if (this.$refs.header != null && this.$refs.header != undefined) {
        this.$refs.header.onClear();
      }
      //await this.fecthListUsertype().then(() => {
      this.fetchListTemplate().then(() => {
        this.processData().then(() => {
          setTimeout(() => {
            // console.log("100");
            if (this.blnloadError == false) {
              this.progress = 100;
            }
          }, 1000);
        });
      });
      // });

      //this.progress = 100;
    },
    async fetchGetTemplatebyId(TemplateId) {
      this.TemplateId = TemplateId;

      //check relate
      await this.relate(TemplateId);

      await axios
        .get(urlapi + apitemplate.getTemplateByID + TemplateId)
        .then((res) => {
          this.listTemplateData = res.data.data;
          // this.blnOpenform = true;
          this.action = "Update";
          this.openform(true);

          // ข้อมูลนี้ลบไปแล้วไม่สามารถแก้ไขได้(กด Edit จากหน้า view)
          if (
            res.data.message ==
            "Not found ,Please check mapping Id in interface"
          ) {
            this.blnOpenform = false;
            this.blnnotedit = true;
            setTimeout(() => {
              this.blnnotedit = false;
              this.processLoadingData();
            }, 5000);
          }
        });
    },
    //ลบข้อมูลด้วย ID
    async fetchDeleteTemplate(state) {
      try {
        if (state === false) {
          this.blnPopupConfirmDelete = !this.blnPopupConfirmDelete;
        } else {
          if (
            this.$refs.AlertPopup.password != null &&
            this.$refs.AlertPopup.password != ""
          ) {
            var strUsername = AdsInventories.userinfo.strUsername.split("@");
            var reslogin = await Login.login(
              strUsername[0],
              state,
              axios,
              urlapi
            );
            var login = {
              username: strUsername[0],
              password: state,
            };
            if (reslogin.logined == true) {
              this.intPinCount = reslogin.pincode;
              this.strTel = reslogin.tel;
              var res = await axios.delete(
                urlapi + apitemplate.deleteTemplate + this.TemplateId,
                {
                  data: login,
                },
                { headers: { "Content-Type": "application/json" } }
              );
              if (res.data.candelete == true) {
                this.blnError = false;
                this.blnPopupConfirmDelete = false;
                this.strSMSGsuccess = "28";
                this.blnOpenform = false;
                this.blnAltStatus = true;
                this.progress = 0;
                //delay 1 sec
                setTimeout(() => {
                  if (this.$refs.header != null) {
                    this.$refs.header.onClear();
                  }
                  this.processLoadingData();
                  this.blnAltStatus = false;
                }, 1000);
              } else {
                this.blnOpenform = false;
                // this.fetchGetTemplatebyId(this.TemplateId);

                this.timerCountHover = 0;
                this.timerEnabled = true;
                this.hover = false;
                this.blnPopupConfirmDelete = false;
                // reset PIN หลังจากDelete
                this.$refs.AlertPopup.password = null;

                //ถ้าลบไปแล้ว ไม่สามารถลบซ้ำได้อีก
                if (
                  res.data.message ==
                  "Cannot delete ,Please check mapping Id in interface"
                ) {
                  //ข้อมูลนี้เพิ่งถูกลบไป ไม่สามารถลบได้
                  this.blnnotdelete = true;
                  setTimeout(() => {
                    this.blnnotdelete = false;
                    this.processLoadingData();
                  }, 5000);
                } else {
                  //ข้อมูลนี้เพิ่งถูกใช้งาน ไม่สามารถลบได้
                  this.blnAltLoadingData = true;
                  setTimeout(() => {
                    this.blnAltLoadingData = false;
                    this.processLoadingData();
                  }, 5000);
                }
              }
              // else {
              //   this.blnAltStatusunsucess = true;
              //   this.timerCountHover = 0;
              //   this.timerEnabled = true;
              //   this.hover = false;
              //   this.blnPopupConfirmDelete = false;
              //   // reset PIN หลังจากDelete
              //   this.$refs.AlertPopup.password = null;
              //   setTimeout(() => {
              //     this.blnAltStatusunsucess = false;
              //   }, 5000);
              // }
              this.blnPopupConfirmDelete = false;

              // reset PIN หลังจากDelete
            } else {
              this.intPinCount = reslogin.pincode;
              this.strTel = reslogin.tel;
              this.intWrongLogin = reslogin.WrongLogin;
              this.errorMsg = this.$t("357", {
                number: this.intPinCount - this.intWrongLogin,
              });
              this.blnError = true;
              this.$refs.AlertPopup.blnShowMSG = true;
              if (this.intWrongLogin >= this.intPinCount) {
                VueCookies.remove("UserInfo", "/", cookiesPath);
              }
            }
          } else {
            this.errorMsg = this.$t("202", { fieldname: "(PIN)" });
            this.blnError = true;
            this.$refs.AlertPopup.blnShowMSG = true;
          }
        }
      } catch (error) {
        this.$refs.AlertPopup.blnShowMSG = true;
        console.log(error);
      }
    },
    async processData() {
      try {
        setTimeout(() => {
          this.ListTemplate.data = Enumerable.from(this.ListTemplate.data)
            .orderByDescending((r) => r.blnUsed)
            .thenByDescending((r) => r.intDefaultset)
            .thenByDescending((r) => r.intTemplatestatus)
            .thenBy((r) => r.strUsertemplatenameEN.toString().toLowerCase())
            .toArray();
        }, 1000);

        if (localStorage.getItem(this.configDisplay) == null) {
          localStorage.setItem(
            this.configDisplay,
            JSON.stringify(this.ListTemplate.defaultField)
          );
        }

        setTimeout(() => {
          if (this.$refs.table != null) {
            this.$refs.table.onClean();
            this.$refs.table.calculatePage(
              this.ListTemplate.total,
              this.ListTemplate.data
            );
            this.$refs.table.setSelectedList(
              JSON.parse(localStorage.getItem(this.configDisplay))
            );
          }
          if (this.$refs.filter != null) {
            this.$refs.filter.Clear();
          }
          //
        }, 100);

        return true;
      } catch (error) {
        this.blnAltLoadingData = true;
        setTimeout(() => {
          this.blnAltLoadingData = false;
        }, 5000);
        throw new Error(error);
      }
    },
    async fecthListUsertype() {
      this.locales = localStorage.getItem("language");
      //ถ้าไม่เจอค่า
      if (this.locales == null || this.locales == "") {
        //ตั้งค่าเริ่มต้นเป็นภาษาอังกฤษ
        this.locales = "en";
      }

      if (this.locales == "en") {
        this.filterUsertype = {
          list: [
            { name: "9" },
            {
              data: [
                { id: null, lang: "79" }, //all
              ],
            },
            { state: "strUsertypeEN" },
          ],
        };
      } else if (this.locales == "th") {
        this.filterUsertype = {
          list: [
            { name: "9" },
            {
              data: [
                { id: null, lang: "79" }, //all
              ],
            },
            { state: "strUsertypeTH" },
          ],
        };
      }
      let res = await axios.get(urlapi + apiusertype.getUserTypeList);

      // console.log(Array.isArray(res.data.data));
      if (Array.isArray(res.data.data)) {
        res.data.data.forEach((element) => {
          this.filterUsertype.list[1].data.push({
            id: element.strID,
            lang:
              this.locales == "en"
                ? element.strUserTypeNameEN
                : element.strUserTypeNameTH,
          });
        });
        this.ListTemplateFilter.push(this.filterUsertype);
        return true;
      }

      return true;
    },
    async fetchListTemplate() {
      this.blnloadError = true;
      await axios
        .get(urlapi + apitemplate.getUserTemplateList)
        .then((res) => {
          this.ListDummy = res.data;
          this.ListTemplate = res.data;
          this.blnloadError = false;
        })
        .catch(() => {
          this.blnAltStatusunsucess = true;
          setTimeout(() => {
            this.blnAltStatusunsucess = false;
          }, 5000);
        });

      return true;
    },
    filter(activeFilters, ListDummy) {
      this.ListTemplate.data = { ...ListDummy };
      let filterArrayintTemplatestatus = [];
      for (let field in this.ListTemplate.data) {
        for (let active in activeFilters) {
          if (activeFilters[active]["state"] == "intTemplatestatus") {
            if (
              this.ListTemplate.data[field]["intTemplatestatus"] ==
              activeFilters[active]["id"]
            ) {
              filterArrayintTemplatestatus.push(this.ListTemplate.data[field]);
            }
          }
        }
      } //console.log(filterArrayintTemplatestatus);

      for (var i in activeFilters) {
        if (activeFilters[i].state !== "intTemplatestatus") {
          filterArrayintTemplatestatus = this.ListTemplate.data[i];
        }
      }

      this.ListTemplate.data = filterArrayintTemplatestatus;
      if (this.$refs.table != null) {
        this.$refs.table.onClean();
        this.$refs.table.calculatePage(
          this.ListTemplate.data.length,
          this.ListTemplate.data
        );
      }
      this.$refs.table.SearchListDataTable(this.strSearch);
      this.ListDummy.data = ListDummy;
    },
    findByTemplate(activeFilters, array) {
      return array.filter((item) => {
        return item[activeFilters.state] == activeFilters.id;
      });
    },
    filteredData(activeFilters) {
      this.activeFiltersSelected = activeFilters;
      if (activeFilters.length != 0) {
        this.filter(activeFilters, this.ListDummy.data);
      } else {
        this.ListTemplate.data = this.ListDummy.data;
        if (this.$refs.table != null) {
          this.$refs.table.onClean();
        }
      }
      this.blnOpenFilter = false;

      // this.activeFiltersSelected = activeFilters;

      // this.filter(activeFilters, this.ListDummy.data);
    },
    openFilter(showFilter) {
      this.blnOpenFilter = showFilter;
    },
    SearcPosition(event) {
      this.strSearch = event;
      if (this.$refs.table != null) {
        this.$refs.table.SearchListDataTable(event);
      }
    },
    clean() {
      this.strSearch = "";
      this.SearcPosition("");
      //this.filteredData(this.activeFiltersSelected);
    },
    selectdata(event) {
      this.selectdatatype = event;
    },
    closeForm(event) {
      this.$emit("onInput", event);
    },
    addData() {
      console.log("add Data");
    },
    async relate(TemplateId) {
      await axios
        .get(urlapi + apitemplate.checkusageusertemplate + TemplateId)
        .then((res) => {
          this.listRelate = { ...res.data };
        });
      // return res.data.used
    },
    async openform(state) {
      try {
        if (state == false) {
          if (this.blnAlerMsgError != true) {
            await this.processLoadingData();
          }
          await this.processLoadingData();
        } else if (state == true) {
          if (this.action == "Insert") {
            this.templateInactive = true; //ปิดปุ่มDelete
            this.listRelate = [];
          }
          if (this.action == "Update") {
            this.templateInactive = this.listRelate.used;
          }
        }
        this.blnOpenform = state;
      } catch (error) {
        console.log(error);
      }
    },
    onSaveConfigDisplay(SelectedList) {
      localStorage.setItem(this.configDisplay, JSON.stringify(SelectedList));
    },
    reloadConfigDisplay() {
      if (this.$refs.table != null) {
        this.$refs.table.setSelectedList(
          JSON.parse(localStorage.getItem(this.configDisplay))
        );
      }
    },
    reloadpage() {
      Login.logout();
      //ไปยังหน้าแรก
      // setTimeout(() => {
      //   this.$router.push("/login");
      //   this.$router.go();
      // }, 100);
    },
    altMsg(event) {
      this.blnAlerMsgError = event;
      //sucess
      if (this.blnAlerMsgError == 1) {
        if (this.action == "Insert") {
          this.blnAltStatus = true;
          this.strSMSGsuccess = "26";
          this.progress = 0;
          setTimeout(() => {
            this.blnAltStatus = false;
            this.processLoadingData();
          }, 1000);
        }
        if (this.action == "Update") {
          this.blnAltStatus = true;
          this.strSMSGsuccess = "27";
          this.progress = 0;
          setTimeout(() => {
            this.blnAltStatus = false;
            this.processLoadingData();
          }, 1000);
        }
      } else if (this.blnAlerMsgError == 0) {
        //con not edit
        this.blnAlerMsgError = true;
        //ข้อมูลนี้เพิ่งถูกลบไป ไม่สามารถแก้ไขได้
        this.blnnotedit = true;
        setTimeout(() => {
          this.blnnotedit = false;
          this.blnAlerMsgError = false;
          this.processLoadingData();
        }, 5000);
      } else if (this.blnAlerMsgError == 2) {
        //cancel button
        this.blnAlerMsgError = false;
      }
    },
    onPreview(data) {
      let intTypeID = 0;
      if (data.strUsertemplatenameEN == "Thai student template default") {
        intTypeID = 1;
      } else if (
        data.strUsertemplatenameEN == "Foreign student template default"
      ) {
        intTypeID = 2;
      } else if (data.strUsertemplatenameEN == "Staff template default") {
        intTypeID = 3;
      } else {
        intTypeID = 1;
      }
      // new tab
      const routeData = this.$router.resolve({
        name: "PreviewTemplate",
        params: { TypeName: intTypeID },
        query: { strTemplateName: data.strUsertemplatenameEN },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="scss">
// .template-form {
//   // width: 90% !important;
//   width: 1500px;
//   position: relative;
//   overflow-x: scroll;
//   .boxer {
//     width: fit-content;
//     .formTemp,
//     .relate {
//       width: 100px;
//     }
//   }
// }
.template-form {
  overflow-x: auto;
}
.box {
  max-width: 1590px;
  position: relative;
  // overflow-x: scroll;
}
.boxer {
  width: fit-content;
}
.lefts,
.rights {
  width: 1473px;
}
.rights {
  height: fit-content;
}
</style>
