<template>
  <!-- <pre>{{ "intTypeID: " + intTypeID }}</pre>
  <pre>{{ "strTypeName: " + strTypeName }}</pre>
  <pre>{{ "action: " + action }}</pre>
  <pre>{{ "intGroupID: " + intGroupID }}</pre> -->
  <div
    class="popup-container align-items-center"
    v-show="blnAltStatusunsucess == true"
  >
    <transition name="zoom">
      <AlertPopup
        v-show="blnAltStatusunsucess == true"
        :altMsgBox="$t('174')"
        class="warning"
        classOkBtn="btn-warning text-white"
        classCancelBtn="text-warning"
        artIcon="WarningOutline"
        :strButtonSubmit="$t('886')"
        :strButtonCancel="$t('35')"
        @confirm="reloaddata($event)"
        classIcon="text-secondary fill-secondary"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnAltStatusunsucess == true"
        @click="blnAltStatusunsucess = true"
      ></div>
    </transition>
  </div>
  <div
    class="popup-container align-items-center"
    v-show="blnGroupchange == true"
  >
    <transition name="zoom">
      <AlertPopup
        v-show="blnGroupchange == true"
        :altMsgBox="$t('1026')"
        class="warning"
        classOkBtn="btn-warning text-white"
        classCancelBtn="text-warning"
        artIcon="WarningOutline"
        :strButtonSubmit="$t('214')"
        :strButtonCancel="$t('215')"
        @confirm="groupchange($event)"
        classIcon="text-secondary fill-secondary"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnGroupchange == true"
        @click="blnGroupchange = true"
      ></div>
    </transition>
  </div>
  <div
    class="popup-container align-items-center"
    v-show="blncheckinsert == true"
  >
    <transition name="zoom">
      <AlertPopup
        v-show="blncheckinsert == true"
        :altMsgBox="$t('174')"
        class="warning"
        classOkBtn="btn-warning text-white"
        classCancelBtn="text-warning"
        artIcon="WarningOutline"
        :strButtonSubmit="$t('886')"
        :strButtonCancel="$t('35')"
        @confirm="addData($event)"
        classIcon="text-secondary fill-secondary"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blncheckinsert == true"
        @click="blncheckinsert = true"
      ></div>
    </transition>
  </div>
  <AltStatus
    :blnalert="blnAltStatus"
    icon="CorrectFill"
    coloricon="fill-success"
    class="success"
    :name="$t(strSMSGsuccess)"
    :class="blnAltStatus ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnAltStatusError"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('174')"
    :class="blnAltStatusError ? 'show' : ''"
  />
  <div
    class="form-template"
    :style="processData == true ? 'height: 1000px;' : ''"
  >
    <skeleonright v-if="processData == true" />
    <div v-if="processData == false">
      <div class="bg-white pt-3 px-5 pb-4 mb-3 shadow-sm rounded w-100">
        <div class="black-form" @click="backToView()">
          <SortArrow width="24" class="black-icon me-1 fill-secondary" />
          <span class="text-warning">{{ $t("906") }}</span>
        </div>
        <div class="w-100 mt-3 d-flex">
          <div class="w-100 d-flex">
            <div class="w-100 me-2">
              <inputText
                ref="inpText"
                :label="$t('150')"
                name="name"
                :value="TypeName"
                @input="InputTemplateName($event)"
                class="w-100"
                :validate="'letterENUnderScore'"
                :request="true"
                :alertMsg="strSMSGalert"
                :showTxtInp="false"
              />
            </div>
            <div class="w-100">
              <SelectUsertype
                :request="true"
                ref="userType"
                :alertMsg="$t('284') + $t('9')"
                strName="9"
                :listDataSelection="listUserType"
                @selected="onSelectUsertype($event)"
                :intDataSelected="intTypeID == 1 ? 1 : intTypeID == 2 ? 2 : 3"
                :canChange="false"
                :blnReturnAll="true"
                :blnSearchBox="true"
                :disable="true"
              />
            </div>
          </div>
          <div class="mx-3 preview d-flex align-items-start mt-2">
            <router-link
              :to="{
                name: 'PreviewTemplate',
                params: { TypeName: intTypeID },
                query: { strTemplateName: TypeName },
              }"
              target="_blank"
            >
              <OpenEyeOutline
                width="32"
                class="h-100"
                style="stroke: #f3933b"
              />
            </router-link>
          </div>
          <div class="" style="width: 15%">
            <Toggle
              @input="onSelectStatus($event)"
              :open="$t('197')"
              :close="$t('198')"
              :blnAuto="true"
              :class="checkallmenustatus() ? 'disabled' : ''"
            />
          </div>
        </div>
      </div>

      <div class="d-flex w-100 m-0">
        <div
          class="bg-white ps-5 pe-2 py-4 shadow-sm rounded left-menu me-3 w-25"
          style="height: fit-content"
        >
          <p class="text-primary fnt-medium mb-4">{{ $t("910") }}</p>
          <div
            class="mb-4 menu"
            v-for="(item, index) in listMenu"
            :key="index"
            @click="selectMenu(item.intGroupID, index)"
          >
            <span class="">
              <CorrectFill
                width="16"
                :class="
                  item.status == false ? 'fill-black-500' : 'fill-success'
                "
              />
            </span>
            <span
              class="fnt-light mx-2"
              :class="
                intGroupID == item.intGroupID ? 'text-primary fnt-medium' : ''
              "
              >{{ $t(item.strGroupName) }}</span
            >
          </div>
        </div>

        <div class="bg-white shadow-sm rounded" v-if="blnLoading">
          <Loading1 />
        </div>
        <div class="rounded right-menu w-100" v-if="!blnLoading">
          <div
            class="fnt-medium fnt-title bg-white px-5 py-4 fnt-bold text-dark w-100 shadow-sm rounded-top border-bottom"
          >
            {{ $t(listUserFields[intTypeID - 1][intindexOfGroupID].formName) }}
            <span class="">
              <CorrectFill
                width="16"
                :class="blnCorrect == false ? 'fill-black-500' : 'fill-success'"
              />
            </span>
          </div>
          <div class="bg-white p-5 rounded-bottom shadow-sm">
      
            <InputAddGroupList
              :formData="listUserFields[intTypeID - 1][intindexOfGroupID]"
              :blnDisable="true"
            />
            <Button
              class="btn btn-primary mt-2"
              :name="$t('929')"
              @input="addData()"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  SortArrow,
  OpenEyeOutline,
  CorrectFill,
} from "@/components/icon/index";
import AlertPopup from "@/components/alert/alertPopup.vue";
import inputText from "@/components/input/inputText.vue";
import SelectUsertype from "@/components/input/dropdownapidata.vue";
import Toggle from "@/components/input/toggle/toggle.vue";
import skeleonright from "@/components/form/Template/SkeletonRight.vue";
import AltStatus from "@/components/alert/alertStatus.vue";
import Loading1 from "@/components/Skeleton.vue";

import axios from "axios";
import urlapi from "@/typescript/urlapi/url";
// import apitemplate from "@/typescript/urlapi/adminsystem/APITemplate";
import apiuserinfo from "@/typescript/urlapi/adminsystem/APIUserInfo";
import InputAddGroupList from "@/components/input/InputAddGroupList.vue";
import Button from "@/components/button/button.vue";
import apitemplate from "@/typescript/urlapi/adminsystem/APITemplate";
import { listUserFieldsID, listUserFields } from "@/typescript/templateData.ts";
import AdsInventories from "@/typescript/inventory";
import Enumerable from "linq";
export default {
  components: {
    SortArrow,
    OpenEyeOutline,
    inputText,
    SelectUsertype,
    Toggle,
    CorrectFill,
    skeleonright,
    InputAddGroupList,
    Button,
    AltStatus,
    AlertPopup,
    Loading1,
  },
  props: {
    intTypeID: { type: Number },
    strTypeName: { type: String },
    action: { type: String },
  },
  watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount++;
        }, 1000);
      }
    },
    hover(value) {
      if (value == false) {
        setTimeout(() => {
          this.timerCount++;
        }, 1000);
      }
    },
    timerCountHover: {
      handler(value) {
        if (value < 2 && this.timerEnabled && this.hover == false) {
          setTimeout(() => {
            this.timerCount++;
          }, 1000);
        }
        if (value >= 2) {
          this.blnAltStatusunsucess = false;
          this.blnAltLoadingData = false;
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
    timerCount: {
      handler(value) {
        if (value < 10 && this.timerEnabled && this.progress < 75) {
          setTimeout(() => {
            this.timerCount++;

            this.progress += 5 * this.timerCount;
          }, 200);
        }
      },

      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  data() {
    return {
      TypeName: "",
      intUsertypeID: 0,
      listMenu: [],
      intGroupID: 1,
      intindexOfGroupID: 0,
      listUserType: Array, //รายการ Usertype
      intStatus: 0,
      intOldGroupID: 0,
      processData: true,
      listUserFieldsID: listUserFieldsID,
      listUserFields: listUserFields,
      blnAltStatusunsucess: false,
      blncheckinsert: false,
      hover: false,
      timerCountHover: 0,
      intOldindex: 0,
      blnGroupchange: false,
      listGroupTemplate: {
        strUsertemplatenameEN: "",
        intDefaultset: 0,
        intUsertypeID: 0,
        intTemplatestatus: 0,
        listTemp: [],
      },
      listUserfieldID: [],
      strUserTemplateID: "",
      blnAltStatus: false, //Alt Status Success
      blnAltStatusError: false,
      strSMSGsuccess: "26",
      blnCorrect: false,
      blnLoading: false,
      strSMSGalert: "46",
    };
  },
  created() {
    this.processLoadingData();
  },
  computed: {
    checkBtnDisable() {
      return this.BtnDisable();
    },
  },
  mounted() {
    if (this.intTypeID == 1) {
      this.listMenu = [
        { intGroupID: 1, strGroupName: "911", status: false },
        { intGroupID: 2, strGroupName: "912", status: false },
        { intGroupID: 3, strGroupName: "913", status: false },
        { intGroupID: 4, strGroupName: "914", status: false },
        { intGroupID: 5, strGroupName: "915", status: false },
        // ข้อมูลบัณฑิตยังไม่มีใน phase 1
        // { intGroupID: 6, strGroupName: "916", status: false },
      ];
    } else if (this.intTypeID == 2) {
      this.listMenu = [
        { intGroupID: 1, strGroupName: "911", status: false },
        { intGroupID: 2, strGroupName: "913", status: false },
        { intGroupID: 7, strGroupName: "912", status: false },
        {
          intGroupID: 8,
          strGroupName: "993",
          status: false,
        },
      ];
    } else if (this.intTypeID == 3) {
      this.listMenu = [
        { intGroupID: 1, strGroupName: "911", status: false },
        { intGroupID: 9, strGroupName: "1003", status: false },
      ];
    }

    if (this.strTypeName != undefined) {
      this.TypeName = this.strTypeName;
      this.listGroupTemplate.strUsertemplatenameEN = this.TypeName;
    }
    // this.intTypeID == 1 || this.intTypeID == 2 ? 282 : 281

    this.processLoadingData();
  },
  methods: {
    checkallmenustatus() {
      let groupstatus = Enumerable.from(this.listMenu).any(
        (a) => a.status == false
      );
      // console.log(groupstatus);
      return groupstatus;
    },
    async processLoadingData() {
      this.processData = true;

      //เรียกฟังก์ชันโหลดรายการ uertype จาก api
      // this.fetchUserType()
      // this.fetchUserfields().then(() => {
      //   setTimeout(() => {
      //     this.processData = false;
      //   }, 1000);
      // });
      try {
        await this.fetchUserType();
        await this.fetchUserfields();

        this.processData = false; // Set loading flag to false after both async functions are called
      } catch (error) {
        this.processData = false; // Set loading flag to false in case of error
        console.error(error); // Handle error, if any
      }
    },
    async fetchUserfields() {
      // console.log(this.intTypeID);
      // console.log(this.listUserFieldsID);
      // apitemplate
      try {
        const res = await axios.post(
          urlapi + apitemplate.getUserFields,
          this.listUserFieldsID[this.intTypeID - 1][this.intindexOfGroupID]
        );
        //  console.log(res);
        this.listUserFields[this.intTypeID - 1][
          this.intindexOfGroupID
        ].fieldsGroup.forEach((group, intGroupIndex) => {
          group.fieldList[0].forEach((row, intRowIndex) => {
            // console.log(row);
            row.forEach((column, intColumnIndex) => {
              res.data.dataFields.forEach((field) => {
                if (field.strID == column.strID) {
                  this.listUserFields[this.intTypeID - 1][
                    this.intindexOfGroupID
                  ].fieldsGroup[intGroupIndex].fieldList[0][intRowIndex][
                    intColumnIndex
                  ] = field;
                  // console.log(this.listUserFields[0][this.intGroupID - 1].fieldsGroup[intGroupIndex].fieldList[intRowIndex][intColumnIndex]);
                }
              });
            });
          });
        });

        // console.log(res.data.dataFields);
      } catch (error) {
        this.blnAltStatusError = true;
        setTimeout(() => {
          this.blnAltStatusError = false;
        }, 5000);
        console.log(error);
      }
    },
    backToView() {
      // console.log("Back to view template");
      this.$emit("closeForm", false);
    },
    selectMenu(intGroupID, indexOfGroupID) {
      let groupstatus = Enumerable.from(this.listMenu)
        .where((w) => w.intGroupID == this.intGroupID)
        .select((s) => s.status)
        .singleOrDefault();

      if (groupstatus == false && this.intGroupID !== intGroupID) {
        this.blnGroupchange = true;
        this.intOldGroupID = intGroupID;
        // = intGroupID;
        this.intOldindex = indexOfGroupID;
      } else {
        this.intGroupID = intGroupID;
        this.intindexOfGroupID = indexOfGroupID;
        // ตรวจสอบสถานะ
        for (let i = 0; i < this.listMenu.length; i++) {
          const element = this.listMenu[i];
          if (element.intGroupID == this.intGroupID && element.status == true) {
            this.blnCorrect = true;
          } else if (
            element.intGroupID == this.intGroupID &&
            element.status == false
          ) {
            this.blnCorrect = false;
          }
        }
        this.fetchUserfields();
      }
      // console.log(intGroupID, indexOfGroupID);
    },
    async groupchange(e) {
      if (e == true) {
        // ตรวจสอบสถานะ
        for (let i = 0; i < this.listMenu.length; i++) {
          const element = this.listMenu[i];
          if (element.intGroupID == this.intGroupID && element.status == true) {
            this.blnCorrect = true;
          } else if (
            element.intGroupID == this.intGroupID &&
            element.status == false
          ) {
            this.blnCorrect = false;
          }
        }

        this.blnGroupchange = false;

        await this.addData();
        this.intGroupID = this.intOldGroupID;
        this.intindexOfGroupID = this.intOldindex;
        this.blnLoading = true;
        await this.fetchUserfields();
        this.blnLoading = false;
      } else {
        this.intGroupID = this.intOldGroupID;
        this.intindexOfGroupID = this.intOldindex;

        this.blnGroupchange = false;
        this.blnLoading = true;
        await this.fetchUserfields();
        this.blnLoading = false;
      }
    },
    reloaddata(e) {
      if (e == true) {
        this.addData();
      } else {
        this.blnAltStatusunsucess = false;
      }
    },
    InputTemplateName(event) {
      // console.log(event);
      this.TypeName = event.target.value;
      this.listGroupTemplate.strUsertemplatenameEN = this.TypeName;
    },
    //เรียกข้อมูล usertype จาก api
    async fetchUserType() {
      try {
        //เรียกข้อมูล usertype จาก api
        var res = await axios.get(urlapi + apiuserinfo.usertype);
        // console.log(res);
        //เก็บข้อมูลจาก api ไปยังตัวแปล listUserType
        this.listUserType = { ...res.data.data };
        // console.log(this.listUserType);
      } catch (error) {
        //ถ้าเชื่อมต่อ api ไม่ได้ให้แสดง msg
        // this.onShowError();
        console.log(error);
        this.blnAltStatusError = true;
        setTimeout(() => {
          this.blnAltStatusError = false;
        }, 5000);
      }
    },
    onSelectUsertype(state) {
      // console.log(state);
      if (state.strID != undefined) {
        this.intUsertypeID = state.strID;
      } else if (state.strID == undefined) {
        this.intUsertypeID = state.id;
      }
      this.listGroupTemplate.intUsertypeID = this.intUsertypeID;
    },
    onSelectStatus(state) {
      if (state == true) {
        this.intStatus = 1;
      } else if (state == false) {
        this.intStatus = 0;
      }
      this.listGroupTemplate.intTemplatestatus = this.intStatus;
      // console.log(state);
      // console.log(this.intStatus);
    },
    tryinsertdata(e) {
      if (e == true) {
        this.addData();
      } else {
        this.blncheckinsert = false;
      }
    },
    async addData() {
      // console.log("addData");
      // console.log("intGroupID: " + this.intGroupID);
      // clear data
      this.listGroupTemplate.listTemp = [];
      // ID Userfield
      const element =
        this.listUserFields[this.intTypeID - 1][this.intindexOfGroupID]
          .fieldsGroup;
      // console.log(element);
      // console.log(this.intTypeID);
      // console.log(this.intindexOfGroupID);
      for (let l = 0; l < element.length; l++) {
        const ele = element[l];
        for (let j = 0; j < ele.fieldList.length; j++) {
          const e = ele.fieldList[j];
          for (let k = 0; k < e.length; k++) {
            for (let l = 0; l < e[k].length; l++) {
              const UFID = e[k][l];
              if (UFID.strID != undefined) {
                const listTemp = {
                  intUserfieldID: UFID.strID,
                  strUtfieldvalue: " ",
                  intHeadinglevel: 0,
                  intInfogroupID: this.intGroupID,
                  intFieldsetID: 0,
                  intFieldorder: 0,
                };
                await this.listGroupTemplate.listTemp.push(listTemp);
              }
            }
          }
        }
      }
      // console.log(this.listGroupTemplate);
      if (
        this.strUserTemplateID == "" ||
        this.strUserTemplateID == undefined ||
        this.strUserTemplateID == null
      ) {
        // เอาตัวที่ไม่มีไอดีออก
        this.listGroupTemplate.listTemp =
          this.listGroupTemplate.listTemp.filter((ele) => {
            return ele.intUserfieldID != undefined;
          });
        if (this.listGroupTemplate.strUsertemplatenameEN != "") {
          await this.fetchInsertUserTemplate();
        } else {
          // ไม่กรอกชื่อให้แสดง alert
          this.$refs.inpText.alertMsgStatus = true;
          this.$refs.inpText.alertMsgInput = this.$t("202", {
            fieldname: this.$t("150").toLocaleLowerCase(),
          });
        }
      } else if (
        this.strUserTemplateID != "" &&
        this.strUserTemplateID != undefined &&
        this.strUserTemplateID != null
      ) {
        // เอาตัวที่ไม่มีไอดีออก
        this.listGroupTemplate.listTemp =
          this.listGroupTemplate.listTemp.filter((ele) => {
            return ele.intUserfieldID != undefined;
          });

        if (this.listGroupTemplate.strUsertemplatenameEN != "") {
          await this.fetchEditUserTemplate();
        } else {
          // ไม่กรอกชื่อให้แสดง alert
          this.$refs.inpText.alertMsgStatus = true;
          this.$refs.inpText.alertMsgInput = this.$t("202", {
            fieldname: this.$t("150").toLocaleLowerCase(),
          });
        }
      }
    },
    async fetchInsertUserTemplate() {
      // console.log(this.listGroupTemplate);
      try {
        const res = await axios.post(
          urlapi +
            apitemplate.insertUserTemplate +
            "?intMemberID=" +
            AdsInventories.userinfo.intMemberID,
          this.listGroupTemplate
        );
        // console.log(res);
        this.strUserTemplateID = res.data.strID;
        if (res.data.inserted == true) {
          // แสดงสถานะ
          for (let i = 0; i < this.listMenu.length; i++) {
            const element = this.listMenu[i];
            if (element.intGroupID == this.intGroupID) {
              element.status = true;
            }
          }
          this.blnCorrect = true;

          // alet status
          this.blnAltStatus = true;
          setTimeout(() => {
            this.blnAltStatus = false;
          }, 2000);
        } else if (res.data.inserted == false) {
          this.blnAltStatusunsucess = false;
        } else if (res.data.code == 500) {
          // this.blnAltStatusError = true;
          // setTimeout(() => {
          //   this.blnAltStatusError = false;
          // }, 2000);
          // this.blnAltStatusunsucess = true;
          this.blnAltStatusError = true;
          setTimeout(() => {
            this.blnAltStatusError = false;
          }, 5000);
        } else {
          this.blnAltStatusError = true;
          setTimeout(() => {
            this.blnAltStatusError = false;
          }, 5000);
        }
        if (res.data.message == "Usertemplate name Duplicate") {
          this.$refs.inpText.alertMsgStatus = true;
          this.$refs.inpText.alertMsgInput = this.$t("221", {
            fieldname: this.$t("5").toLocaleLowerCase(),
          });
        }
        // console.log(this.strUserTemplateID);
      } catch (error) {
        console.log(error);
        // this.blnAltStatusunsucess = true;
        // setTimeout(() => {
        //   this.blnAltStatusunsucess = false;
        // }, 5000);
        this.blnAltStatusError = true;
        setTimeout(() => {
          this.blnAltStatusError = false;
        }, 5000);
      }
    },
    async fetchEditUserTemplate() {
      // console.log(this.listGroupTemplate);
      try {
        const res = await axios.put(
          urlapi +
            apitemplate.updateUserTemplate +
            "?MemberID=" +
            AdsInventories.userinfo.intMemberID +
            "&strUserTemplateID=" +
            this.strUserTemplateID +
            "&intInfogroupID=" +
            this.intGroupID,
          this.listGroupTemplate
        );
        // console.log(res);
        if (res.data.edited == true) {
          // แสดงสถานะ
          for (let i = 0; i < this.listMenu.length; i++) {
            const element = this.listMenu[i];
            if (element.intGroupID == this.intGroupID) {
              element.status = true;
            }
          }
          this.blnCorrect = true;

          // alet status
          this.blnAltStatus = true;
          setTimeout(() => {
            this.blnAltStatus = false;
          }, 2000);
        }
        if (res.data.code == 500 && res.data.edited == false) {
          // this.blnAltStatusError = true;
          // setTimeout(() => {
          //   this.blnAltStatusError = false;
          // }, 2000);
          this.blnAltStatusunsucess = true;
        } else if (res.data.message == "Usertemplate name Duplicate") {
          this.$refs.inpText.alertMsgStatus = true;
          this.$refs.inpText.alertMsgInput = this.$t("221", {
            fieldname: this.$t("5").toLocaleLowerCase(),
          });
        }
      } catch (error) {
        console.log(error);
        this.blnAltStatusunsucess = false;
        setTimeout(() => {
          this.blnAltStatusunsucess = true;
        }, 300);
      }
    },

    BtnDisable() {
      let disable = false;
      for (let i = 0; i < this.listMenu.length; i++) {
        const element = this.listMenu[i];
        if (element.status == true && element.intGroupID == this.intGroupID) {
          disable = true;
        }
      }
      return disable;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-template {
  width: 1256px;

  .black-form {
    cursor: pointer;
    width: fit-content;

    &:hover {
      text-decoration: underline;
      color: $secondary;
    }

    .black-icon {
      transform: rotate(90deg);
    }
  }

  .menu {
    &:hover {
      cursor: pointer;
    }
  }

  .btn-disable {
    background-color: $black-400 !important;
    border-color: $black-400 !important;
    color: $white;
    pointer-events: none;
  }
}
</style>
